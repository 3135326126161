* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#alert {
  display: none;
}
.row {
  margin: auto 0 !important;
}
#about-page {
  padding: 1rem 0;
}
#about-page h1,
#about-page h2,
#about-page h3,
#about-page h4,
#about-page h5,
#about-page h6 {
  font-weight: bold !important;
}
#about-page .btn-blue {
  background-color: #0b4995;
  color: #fff;
  border: 1px solid #0b4995 !important;
}
#about-page .btn-blue:hover {
  background-color: #fff;
  color: #0b4995;
}
#about-page .btn-lightgray {
  background-color: #787272;
  color: #fff;
}

#about-page .btn-orange {
  background-color: #f87330 !important;
  color: #fff;
}

#about-page .btn-orange:focus,
.btn-orange:hover {
  background-color: white !important;
}
/* ----------------------------------
        About Us Page
----------------------------------- */

.aboutUs-header {
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/header.webp");
  height: 350px;
  background-blend-mode: overlay;
}

.aboutus-page p {
  color: #585858;
}

.aboutUs-feature {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9rem !important;
}

@media (max-width: 767px) {
  .aboutUs-feature {
    position: unset;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(0);
  }
}

.about-section {
  background-color: #ffe8c5;
  width: 98%;
}

.introduction-circle {
  position: absolute;
  clip-path: ellipse(100vw 80vw at 50% 100%);
  background: rgb(241, 241, 241);
  background: linear-gradient(
    180deg,
    rgba(241, 241, 241, 1) 0%,
    rgba(241, 241, 241, 0.66) 100%
  );
  top: -60%;
  left: 0;
  width: 100%;
  height: 160%;
  z-index: -1;
}

.track-record-circle {
  clip-path: ellipse(75vw 13vw at 50% 100%);
  background: rgb(255, 234, 203);
  background: linear-gradient(
    180deg,
    rgba(255, 234, 203, 1) 0%,
    rgba(241, 241, 241, 0) 100%
  );
  position: absolute;
  top: -40%;
  left: 0;
  width: 100%;
  height: 200px;
  z-index: -1;
}

@media (max-width: 992px) {
  .track-record-circle {
    clip-path: ellipse(75vw 13vw at 50% 100%);
    top: -80%;
  }
}

@media (max-width: 768px) {
  .track-record-circle {
    clip-path: ellipse(75vw 20vw at 50% 100%);
    top: -50%;
  }
}

@media (max-width: 576px) {
  .track-record-circle {
    clip-path: ellipse(75vw 30vw at 50% 100%);
    top: -50%;
  }
}
