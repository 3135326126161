:root {
  --color-black: #0c0c0c;
  --color-blue: #1976d2;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}

.sectionHeading {
  padding: 0.8rem 2rem;
  background-color: #1e1e1e;
  color: #ffffff;
  font-weight: bold;
}

.form-control:focus-visible {
  forced-color-adjust: none;
}
.form-control {
  padding: 0.5rem;
}

.multiLineLabel {
  display: inline-block;
  box-sizing: border-box;
  white-space: pre-line;
  word-wrap: break-word;
}

.multiLineLabel .textMaxLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.multiLineLabel .textMaxLineTwo {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.multiLineLabel .textMaxLineThree {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.multiLineLabel .textMaxLine > p,
.multiLineLabel .textMaxLineTwo > p,
.multiLineLabel .textMaxLineThree > p {
  display: contents;
}
.multiLineLabel .textMaxLineTwo > ul,
.multiLineLabel .textMaxLineThree > ul {
  display: none;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.firebase-emulator-warning {
  display: none;
}
